.App {
  height: 100vh;
  background: #2e497c;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.tweet {
  border: 2px solid black;
  height: 200px;
  width: 200px;
  background: cornsilk;
}
